<template>
  <div :class="styles({ variant, class: props.class })">
    <slot>
      <slot name="header">
        <CardHeader>
          <slot name="title">
            <CardTitle v-if="title || $slots.title" :title="title" />
          </slot>
          <slot name="description">
            <CardDescription
              v-if="description || $slots.description"
              :description="description"
            />
          </slot>
        </CardHeader>
      </slot>
      <slot name="content" v-if="content || $slots.content">
        <CardContent>
          <div v-html="content"></div>
        </CardContent>
      </slot>
      <slot name="footer"></slot>
    </slot>
  </div>
</template>

<script lang="ts" setup>
const styles = tv({
  base: "rounded-lg border border-transparent bg-card text-card-foreground shadow-sm",
  variants: {
    variant: {
      outline: "bg-transparent border-card",
    },
  },
});

const props = defineProps<{
  title?: string;
  description?: string;
  content?: string;
  class?: any;
  variant?: keyof typeof styles.variants.variant;
}>();
</script>
