<template>
  <div :class="styles({ class: props.class })">
    <slot>
      {{ title }}
    </slot>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title?: string;
  class?: any;
}>();
const styles = tv({
  // base: "text-2xl font-semibold leading-none tracking-tight",
  base: "font-medium",
});
</script>
<!-- 
<div class="flex font-medium">
  <span>{{ method.title }}</span>
  <span class="ml-4 inline-flex gap-1">
    <Badge variant="secondary" v-for="tag in method.tags">
      {{ tag }}
    </Badge>
  </span>
</div> -->
